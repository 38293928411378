<template>
    <div id="all-ports">
        <h3>{{ $t('ports.allPorts') }}</h3>
        <div class="card mb-3 filter-container hide-print">
            <div @click="toggleFilters" class="card-header filter-header">
                {{ $t('ports.search') }}
                <span class="toggle-arrow"><i class="fas fa-arrow-circle-down"></i></span>
            </div>
            <div class="card-body filter-body collapse">
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">{{ $t('ports.name') }}</label>
                                <input
                                    v-model="selectedPortName"
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    :placeholder="$t('ports.portName')"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="address">{{ $t('ports.address') }}</label>
                                <input
                                    v-model="selectedPortAddress"
                                    type="text"
                                    class="form-control"
                                    id="address"
                                    :placeholder="$t('ports.portAddress')"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="bodyOfWaterType">{{ $t('ports.bodyOfWaterType') }}</label>
                                <multiselect
                                    v-model="selectedBodyOfWaterTypes"
                                    select-label=""
                                    deselect-label=""
                                    selected-label=""
                                    label="description"
                                    :options="bodyOfWaterTypes"
                                    :placeholder="$t('ports.choose')"
                                    :multiple="true"
                                    track-by="type"
                                >
                                    <template slot="tag" slot-scope="{ option, remove }"><span class="multiselect__tag">{{ option.description }}<i
                                        aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i></span>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="water">{{ $t('ports.bodyOfWaterName') }}</label>
                                <multiselect
                                    v-model="selectedBodyOfWaterNames"
                                    select-label=""
                                    deselect-label=""
                                    selected-label=""
                                    label="name"
                                    :options="filteredBodyOfWaterNames"
                                    :placeholder="$t('ports.choose')"
                                    :multiple="true"
                                    track-by="code"
                                >
                                    <template slot="tag" slot-scope="{ option, remove }"><span class="multiselect__tag">{{ option.name }}<i
                                        aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i></span>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="portTask">{{ $t('ports.portFunction') }}</label>
                                <multiselect
                                    v-model="selectedPortTasks"
                                    select-label=""
                                    deselect-label=""
                                    selected-label=""
                                    label="description"
                                    :options="portTasks"
                                    :placeholder="$t('ports.choose')"
                                    :multiple="true"
                                    track-by="type"
                                    :taggable="true"
                                >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">{{ option.description }}<i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i></span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span class="multiselect__option"><div class="option__title"><p id="taskLabel">{{ props.option.description }}</p></div></span>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('ports.serveForeign') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        v-model="selectedIsForForeignVessels"
                                        type="checkbox"
                                        id="portForeign"
                                    />
                                    <label class="form-check-label" for="portForeign">{{ $t('ports.serveForeignLabel') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('ports.additionalServices') }}</label>
                        <div class="row">
                            <div class="col" v-for="(group, index) in additionalServiceGroups" :key="index">
                                <div class="form-check" v-for="service in group.services" :key="service.code">
                                    <input
                                        class="form-check-input"
                                        v-model="selectedAdditionalServices"
                                        type="checkbox"
                                        :value="service.code"
                                        :id="`additional-service-option-${service.code}`"
                                    />
                                    <label class="form-check-label" :for="`additional-service-option-${service.code}`">
                                        {{service.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        @click.prevent="clear"
                        class="btn btn-secondary"
                    >
                        {{ $t('ports.emptyFields') }}
                    </button>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <template v-if="!loading">{{ $t('ports.found') }} {{ filteredPorts.length }} {{ $t('ports.ports') }}</template>
                <template v-else>
                    <pulse-loader class="spinner" :loading="loading" :color="color" :size="size"></pulse-loader>
                </template>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th>{{ $t('ports.portName') }}</th>
                            <th>{{ $t('ports.portAddress') }}</th>
                            <th>{{ $t('ports.portPhone') }}</th>
                            <th>{{ $t('ports.portEmail') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(port, i) in filteredPorts" :key="i">
                            <td :data-title="$t('ports.portName')" id="portDetailsPath" class="text-capitalize">
                                <a :href="portDetailsPath(port.publicId)">{{ port.name }}</a>
                            </td>
                            <td :data-title="$t('ports.portAddress')">{{ port.address }}</td>
                            <td :data-title="$t('ports.portPhone')">
                                <div v-for="phone in port.phoneNumbers" :key="phone">
                                    <a :href="'tel:' + phone">{{ phone }}</a>
                                </div>
                            </td>
                            <td :data-title="$t('ports.portEmail')">
                                <a v-if="port.email != '-'"
                                   :href="'mailto:' + port.email"
                                   target="_top"
                                >{{ port.email }}</a>
                                <p v-else> {{ port.email }}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PortsDataService from "../service/PortsDataService";
import EventBus from "../eventBus";
import i18n from "@/i18n";

export default {
    name: "AllPorts",
    data() {
        return {
            ports: [],
            bodyOfWaterNames: [],
            additionalServices: [],
            selectedPortName: "",
            selectedPortAddress: "",
            selectedBodyOfWaterTypes: [],
            selectedBodyOfWaterNames: [],
            selectedPortTasks: [],
            selectedAdditionalServices: [],
            selectedIsForForeignVessels: false,
            color: "white",
            size: "0.75rem",
            loading: true,
            lang: i18n.locale ?? 'et',
        };
    },
    computed: {
        bodyOfWaterTypes() {
            return [
                {type: 1, description: this.$i18n.t('bodyOfWaterTypes.navigableInlandWaterBody')},
                {type: 2, description: this.$i18n.t('bodyOfWaterTypes.sea')},
            ]
        },
        portTasks() {
            return [
                {type: 1, description: this.$i18n.t('portTasks.big')},
                {type: 2, description: this.$i18n.t('portTasks.paidAndSmall')},
                {type: 3, description: this.$i18n.t('portTasks.small')},
            ]
        },
        additionalServiceGroups() {
            const result = [];
            this.additionalServices.forEach((service, i) => {
                if (i % 5 === 0) {
                    result.push({ services: [] });
                }
                result[result.length -1].services.push(service);
            });
            return result;
        },
        filteredBodyOfWaterNames() {
            return this.bodyOfWaterNames.filter(
                this.selectedBodyOfWaterTypes.length === 1 && this.selectedBodyOfWaterTypes[0].type === 1
                    ? n => n.type === 1
                    : this.selectedBodyOfWaterTypes.length === 1 && this.selectedBodyOfWaterTypes[0].type === 2
                        ? n => n.type === 2
                        : _ => true);
        },
        filteredPorts() {
            return this.ports.filter(port => 
                port.name.toLowerCase().includes(this.selectedPortName.toLowerCase().trim())
                && port.address.toLowerCase().includes(this.selectedPortAddress.toLowerCase().trim())
                && (this.selectedBodyOfWaterTypes.length
                    ? this.selectedBodyOfWaterTypes.map(t => t.type).includes(port.bodyOfWaterType) 
                    : true)
                && (this.selectedBodyOfWaterNames.length
                    ? this.selectedBodyOfWaterNames.map(t => t.code).includes(port.bodyOfWaterName) 
                    : true)
                && (this.selectedPortTasks.length
                    ? this.selectedPortTasks.map(t => t.type).includes(port.portFunction) 
                    : true)
                && (this.selectedAdditionalServices.length
                    ? this.selectedAdditionalServices.every(selectedCode => port.additionalServices.includes(selectedCode)) 
                    : true)
                && (this.selectedIsForForeignVessels
                    ? this.selectedIsForForeignVessels === port.isForForeignVessels 
                    : true)
            );
        }
    },
    methods: {
        async queryPorts() {
            await PortsDataService.queryPorts().then(response => {
                this.ports = response.data;
            });
        },
        async queryFilterOptions() {
            await PortsDataService.queryReservoirs().then(response => {
                const collator = new Intl.Collator('et', {
                    numeric: true,
                    sensitivity: 'base'
                });
                this.bodyOfWaterNames = response.data.sort(function (a, b) {
                    return collator.compare(a.name, b.name)
                });
            });

            await PortsDataService.queryAdditionalServiceTypes().then(response => {
                const collator = new Intl.Collator('et', {
                    numeric: true,
                    sensitivity: 'base'
                });
                
                response.data.push({ code: "MUU", name: this.$i18n.t('ports.otherAdditionalService')});
                
                this.additionalServices = response.data.sort(function (a, b) {
                    return collator.compare(a.name, b.name)
                });
            });
        },
        clear() {
            this.selectedPortName = "";
            this.selectedPortAddress = "";
            this.selectedBodyOfWaterNames = [];
            this.selectedBodyOfWaterTypes = [];
            this.selectedPortTasks = [];
            this.selectedAdditionalServices = [];
            this.selectedIsForForeignVessels = false;
        },
        portDetailsPath(publicId) {
            return "/sadam/" + publicId;
        },
        toggleFilters() {
            const filterBody = document.querySelector(".filter-body");
            const toggleArrow = document.querySelector(".toggle-arrow i");

            if (filterBody.classList.contains('collapse')) {
                this.openFilters();
            } else {
                filterBody.classList.add("collapse");
                toggleArrow.classList.add("fa-arrow-circle-down");
                toggleArrow.classList.remove("fa-arrow-circle-up");
            }
        },
        openFilters() {
            const filterBody = document.querySelector(".filter-body");
            const toggleArrow = document.querySelector(".toggle-arrow i");
            filterBody.classList.remove("collapse");
            toggleArrow.classList.remove("fa-arrow-circle-down");
            toggleArrow.classList.add("fa-arrow-circle-up");
        }
    },
    async mounted() {
        if (localStorage.getItem('portTask') != null) {
            this.selectedPortTasks.push(JSON.parse(localStorage.getItem('portTask')));
            this.openFilters();
        }
        
        if (localStorage.getItem('filterOpened') === "opened") {
            this.openFilters();
        }
        
        await this.queryPorts();
        await this.queryFilterOptions();
        this.loading = false;
        
        EventBus.$on("COLOR", payload => {
            this.color = payload.color;
        });
    },
};
</script>

<style scoped>

#portDetailsPath {
    cursor: pointer;
    color: #0D73BB;
    text-decoration: underline;
}

td {
    padding-right: 0.9375rem;
}

.spinner {
    display: inline;
}

.multiselect__option {
    white-space: normal;
}

.multiselect__tag, .multiselect__tag-icon {
    background-color: #C2E8FB !important;
    color: #0D73BB !important;
    white-space: normal !important;
}

</style>
